.activity-stream {
  box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;

  &--under-tabs {
    border-radius: 0 0 4px 4px;
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }

  &--headless {
    border-radius: 0;
    margin: 0;
    box-shadow: none;

    .detailed-status,
    .status {
      border-radius: 0 !important;
    }
  }

  div[data-component] {
    width: 100%;
  }

  .entry {
    background: $ui-base-color;

    .detailed-status,
    .status,
    .load-more {
      animation: none;
    }

    &:last-child {
      .detailed-status,
      .status,
      .load-more {
        border-bottom: 0;
        border-radius: 0 0 4px 4px;
      }
    }

    &:first-child {
      .detailed-status,
      .status,
      .load-more {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        .detailed-status,
        .status,
        .load-more {
          border-radius: 4px;
        }
      }
    }

    .status.quote-status {
      border: solid 1px #9baec8;
      border-radius: 4px;
      padding: 5px;
      margin-top: 15px;
      cursor: pointer;

      .status__avatar {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 5px;
        left: 5px;

        & > div {
          width: 18px;
          height: 18px;
        }
      }
    }

    @media screen and (max-width: 740px) {
      .detailed-status,
      .status,
      .load-more {
        border-radius: 0 !important;
      }
    }
  }

  &.with-header {
    .entry {
      &:first-child {
        &,
        .detailed-status.light,
        .status.light {
          border-radius: 0;
        }

        &:last-child {
          &,
          .detailed-status.light,
          .status.light {
            border-radius: 0 0 4px 4px;
          }
        }
      }

      .status.light.quote-status {
        border: solid 1px #9baec8;
        border-radius: 4px;
        padding: 5px;
        margin-top: 15px;
        cursor: pointer;

        .status__avatar {
          height: 18px;
          width: 18px;
          position: absolute;
          top: 5px;
          left: 5px;

          & > div {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .media-gallery__gifv__label {
    bottom: 9px;
  }

  .status.light {
    padding: 14px 14px 14px (48px + 14px * 2);
    position: relative;
    min-height: 48px;
    cursor: default;

    .status__header {
      font-size: 15px;

      .status__meta {
        float: right;
        font-size: 14px;

        .status__relative-time {
          color: $lighter-text-color;
        }
      }
    }

    .status__display-name {
      display: block;
      max-width: 100%;
      padding-right: 25px;
      color: $inverted-text-color;
    }

    .status__avatar {
      position: absolute;
      left: 14px;
      top: 14px;
      width: 48px;
      height: 48px;

      & > div {
        width: 48px;
        height: 48px;
      }

      img {
        display: block;
        border-radius: 4px;
      }
    }

    .display-name {
      display: block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      strong {
        font-weight: 500;
        color: $inverted-text-color;

        @each $lang in $cjk-langs {
          &:lang(#{$lang}) {
            font-weight: 700;
          }
        }
      }

      span {
        font-size: 14px;
        color: $light-text-color;
      }
    }

    .status__content {
      color: $inverted-text-color;

      a {
        color: $highlight-text-color;
      }

      a.status__content__spoiler-link {
        color: $primary-text-color;
        background: $ui-base-color;

        &:hover {
          background: lighten($ui-base-color, 8%);
        }
      }
    }
  }
}

.button.logo-button {
  flex: 0 auto;
  font-size: 14px;
  background: $ui-highlight-color;
  color: $primary-text-color;
  text-transform: none;
  line-height: 36px;
  height: auto;
  padding: 3px 15px;
  border: 0;

  svg {
    width: 20px;
    height: auto;
    vertical-align: middle;
    margin-right: 5px;
    fill: $primary-text-color;
  }

  &:active,
  &:focus,
  &:hover {
    background: lighten($ui-highlight-color, 10%);
  }

  &:disabled,
  &.disabled {
    &:active,
    &:focus,
    &:hover {
      background: $ui-primary-color;
    }
  }

  &.button--destructive {
    &:active,
    &:focus,
    &:hover {
      background: $error-red;
    }
  }

  @media screen and (max-width: $no-gap-breakpoint) {
    svg {
      display: none;
    }
  }
}

.embed,
.public-layout {
  .status__content[data-spoiler=folded] {
    .e-content {
      display: none;
    }

    p:first-child {
      margin-bottom: 0;
    }
  }

  .detailed-status {
    padding: 15px;
  }

  .status {
    padding: 15px 15px 15px (48px + 15px * 2);
    min-height: 48px + 2px;

    &__avatar {
      left: 15px;
      top: 17px;

      .account__avatar {
        width: 48px;
        height: 48px;
      }
    }

    &__content {
      padding-top: 5px;
    }

    &__prepend {
      margin-left: 48px + 15px * 2;
      padding-top: 15px;
    }

    &__prepend-icon-wrapper {
      left: -32px;
    }

    .media-gallery,
    &__action-bar,
    .video-player {
      margin-top: 10px;
    }

    &__action-bar-button {
      font-size: 18px;
      width: 23.1429px;
      height: 23.1429px;
      line-height: 23.15px;
    }
  }
}
